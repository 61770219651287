import axios from 'axios'
import axiosInstance from './authAxios'
import { decrypt } from './decryption'


let baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOCAL_URL : process.env.REACT_APP_REMOTE_URL
let apiKey = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOCAL_API_KEY : process.env.REACT_APP_REMOTE_API_KEY

let encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

//secured routes
export const getAllBuilding = async () => {
    try {
        let response = await axiosInstance.post(`${baseUrl}/secured/building/all`)
        let decryptedData = decrypt(response.data.encryptedData, encryptionKey)
        return {data: JSON.parse(decryptedData)}
    } catch(error) {
        console.log("Error while getting the building list ", error)
    }
}
export const getBuildings = async (venueName) => {
    try {
        let response = await axiosInstance.post(`${baseUrl}/secured/building/get/venue`, {venueName})
        let data = decrypt(response.data.encryptedData, encryptionKey)

        return {data: {data: JSON.parse(data), status: true}}
    } catch(error) {
        console.log("Error while gettign the venue ", error)
    }
}
export const getPatch = async (buildingId) => {
    try {
        let response = await axiosInstance.post(`/secured/patch/get`, { id: buildingId })
        let data = decrypt(response.data.encryptedData, encryptionKey)
        return { data: {patchData: JSON.parse(data), patchExist: true} }
    } catch(error) {
        console.log("Error while getting the patch data ", error)
    }
}
export const getPolyline = async (buildingId) => {
    try {
        let response = await axiosInstance.post(`${baseUrl}/secured/polyline`, { id: buildingId })
        let decryptedData = decrypt(response.data.encryptedData, encryptionKey)
        return {data: {polyline: JSON.parse(decryptedData), polylineExist: true}}
    } catch(error) {
        console.log("Error while getting the polyline data ", error)
    }
}
export const getEntries = async (buildingID) => {
    try {
        return await axiosInstance.post(`/secured/landmark/entries`, {id: buildingID})
    } catch(error) {
        console.log("Error while getting the entries", error)
    }
}
export const getLandmarksBySearch = async (post) => {
    try {
        let response = await axiosInstance.post(`/secured/landmarks/search/venues`, post)

        let data = decrypt(response.data.encryptedData, encryptionKey)
        return {data: {status: true, data: JSON.parse(data)}}
    } catch(error) {
        console.log("Error while getting the landmark search ", error)
    }
}

export const getLandmarksByFloor = async (post) => {
    try {
        return await axiosInstance.post(`/secured/landmarks/floor`, post)
    } catch(error) {
        console.log("Error while getting the landmarks by floor ", error)
    }
}

export const getMultibuildingRoute = async (post) => {
    try {
        return await axiosInstance.post(`/secured/mapbox/routing`, post)
    } catch(error) {
        console.log("Error while getting the route ", error)
    }
}

export const getLandmarks = async (buildingID) => {
    try {
        let response = await axiosInstance.post(`/secured/landmarks`, {id: buildingID})
        let decryptedData = decrypt(response.data.encryptedData, encryptionKey)
        return {data: {landmarks: JSON.parse(decryptedData), landmarkExist: true}}
    }
    catch(error) {
        console.log("Error while getting the landmarks data ", error)
    }
}
export const getIntrabuildingPath = async (post) => {
    try {
        return await axiosInstance.post(`/secured/path`, post)
    } catch(error) {
        console.log("Error while adding getting the path ", error)
    }
}
export const getInterbuildingPath = async (post) => {
    try {
        return await axios.post(`/secured/path`, post)
    } catch(error) {
        console.log("Error while getting the interbuilding path ", error)
    }
}
export const getWayfindingPath = async (post) => {
    try {
        let response = await axiosInstance.post(`/secured/indoor-path-finding`, post)
        let decryptedData = decrypt(response.data.encryptedData, encryptionKey)
        
        console.log(JSON.parse(decryptedData))
        return {data: JSON.parse(decryptedData)}
    }catch(error) {
        console.log("Error while getting the wayfinding path ", error)
    }
}
export const getOutdoorPath = async (post) => {
    try {
        return await axiosInstance.post(`/secured/outdoor-path`, post)
    } catch(error) {
        console.log("Error while getting the outdoor path ", error)
    }
}

export const getOutDoorCampus = async (post) => {
    try {
        let response = await axiosInstance.post(`/secured/outdoor`, post)
        let data = decrypt(response.data.encryptedData, encryptionKey)
        return {data: {data: JSON.parse(data), status: true}}
    } catch(error) {
        console.log("Error while getting the outdoor campus ", error)
    }
}

export const getOutdoorWayfinding = async (post) => {
    try {
        return await axiosInstance.post('/secured/outdoor-wayfinding', post)
    } catch(error) {
        console.log("Error while getting the outdoor wayfinding ", error)
    }
}

export const getPolylineByFloor = async (post) => {
    try {
        return await axiosInstance.post('/secured/polyline/floor', post)
    } catch(error) {
        console.log("Error while getting the polyline data by floor ", error)
    }
}

export const getUserDetails = async (post) => {
    try {
        return await axiosInstance.post('/secured/user/get', post)
    }catch(error) {
        console.log("Error while getting the user details ", error)
    }
}



//auth routes
export const guestLogin = async () => {
    try {
        return await axios.get(`${baseUrl}/auth/guest?API_KEY=${apiKey}`)
    } catch(error) {
        console.log("Error while login in as guest ", error)
    }
}

export const sendOtp = async (post) => {
    try {
        return await axios.post(`${baseUrl}/auth/otp/send`, post, { headers: { 'x-access-token': apiKey } })
    } catch(error) {
        console.log("Error while sending the otp ", error)
    }
}

export const signup = async (post) => {
    try {
        return await axios.post(`${baseUrl}/auth/signup`, post)
    } catch(error) {
        console.log("Error while signing up the user ", error)
    }
}

export const signin = async (post) => {
    try {
        return await axios.post(`${baseUrl}/auth/signin2`, post)
    } catch(error) {
        console.log("Error while signing in ", error)
    }
}
export const resetPassword = async (post) => {
    try {
        return await axiosInstance.post(`${baseUrl}/auth/reset-password`, post)
    } catch(error) {
        console.log("Error while reseting the password ", error)
    }
}

export const sendOtpForAccountVerify = async (post) => {
    try {
        return await axios.post(`${baseUrl}/auth/otp/username`, post, {
            headers: {
                'x-access-token': process.env.REACT_APP_API_KEY,
                // Add any additional headers here
            }
        })
    } catch(error) {
        console.log("Error while sending the otp for verification ", error)
    }
}

export const veryfyOtpAndGenerateToken = async (post) => {
    try {
        return await axios.post(`${baseUrl}/auth/otp/token`, post)
    }catch(error) {
        console.log("Error while getting the token")
    }
}

export const resetPasswordUsingToken = async (post) => {
    try {
        let creds = {
            username: post.username,
            password: post.password
        }
        const instance = axios.create({
            baseURL: baseUrl,
            // timeout: 1000,
            headers: {'x-access-token': post.token}
          });
        return await instance.post(`/secured/user/reset-password`, creds, {headers: { 'Authorization' : apiKey }})
    }catch(error) {
        console.log("Error while reseting the password")
    }
}


export const getGoogleUser = async () => {
    try {
        return await axios.get(`${baseUrl}/auth/login/success`, { withCredentials: true })

    } catch(error) {
        console.log("Error while getting google data ", error)
    }
}

export const getUsername = async (username,appId) => {
    try {
        let axiosInstance = axios.create({
            baseURL: baseUrl,
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        })
        return await axiosInstance.post(`${baseUrl}/auth/username`, { username,appId })
    } catch(error) {
        console.log("Error while getting the username", error)
    }
}

export const logout = async (post) => {
    try {
        return await axios.delete(`${baseUrl}/api/refreshToken/delete`, post)
    } catch(error) {
        console.log("Error while logging out ", error)
    }
}

export const getLandmarkByQr = async (code) => {
    try {
        return await axios.post(`${baseUrl}/api/qr`, {code})
    } catch(error) {
        console.log("Error while getting the landmark by code ", error)
    }
}