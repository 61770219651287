import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

const Transition = () => {
    const { appUrl } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    let appStore = searchParams.get("appStore")
    let playStore = searchParams.get("playStore")
    let bid = searchParams.get("bid")
    let landmark = searchParams.get("landmark")

    let query = window.location.href.split('?')[1]

    useEffect(() => {
        window.location.assign(`iwayplus://${appUrl}/landmark?bid=${bid}&landmark=${landmark}`)

        let appTimeout = setTimeout(() => {

            if(navigator.platform.includes("iPhone") || navigator.platform.includes("Mac")) window.location.assign(`https://apps.apple.com/in/app/${appStore}`)
            else window.location.assign(`https://play.google.com/store/apps/details?id=${playStore}`)

        }, 3000)

        return () => clearTimeout(appTimeout)

    }, [])

    return (
        // <div onClick={() => window.location.href = "https://www.google.com"} >hello from transition on  deeplink:- iwayplus://{appUrl}/landmark?bid={bid}&landmark={landmark} {navigator.platform}</div>
        <div>Please wait...</div>
    )
}

export default Transition